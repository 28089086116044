html {
    overflow-x: hidden;
}

a {
    display:contents;
    text-decoration: none;
}

body {
    background-image: url("/src/assets/img/banner-bg.png");
    color: #8b8b8b;
    text-align: center;
}

    .container {
        display: flex;
        justify-content: center;
        margin-top: -80px;
        padding: 8rem;
        border-radius: 100px;
    }
    
    .container-img {
        width: 100px;
        margin-bottom: -7.5%;
        margin-top: 5%;
        border-radius: 100px;
        border-color: #b8b8b8;
        border-style: solid;
    }
    
    .container-text {
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 14px;
        color: #b8b8b8;
        margin-top: -10%;
    }
    
    .linkImage {
        display: flex;
        background-color: #692424;
        border-radius: 100px;
        padding: 1.75rem;
        margin: 15px 0;
        height: 1.75rem;
        max-width: 33%;
        margin-left: auto;
        margin-right: auto;
        
    }
    
    .linkImage>img {
        display: flex;
        height: 125%;
        height: 2.75rem;
        margin: -1.5% 0 -2% 0;
    }
    
    .linkImage p {
        display: flex;
        font-weight: 600;
        color: #b8b8b8;
        font-size: 18px;
        margin-left: auto;
        margin-top: auto;
        align-items: center;
    
    }
    .linkImage:hover {
        scale: 102%;
    }

    @media screen and (max-height: 1920px) {
        .container-img{
        width: 100px;
        margin-top: 2%;
        margin-bottom: 5%;
        }
    }

    @media screen and (min-height: 1366px) {
        .container-img {
            width: 100px;
            margin-bottom: -15.5%;
            margin-top: 5%;
        } 
    }

@media screen and (min-height: 1194px) {
    .container-img {
        width: 100px;
        margin-bottom: -17.5%;
        margin-top: 5%;
    } 
}

@media screen and (max-height: 960px) {
.container {
    display: flex;
    justify-content: center;
    margin-top: -80px;
    padding: 8rem;
    border-radius: 100px;
}

.container-img {
    width: 100px;
    margin-bottom: -75%;
}

.container-text {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    margin-top: -5%;
}

.linkImage {
    display: flex;
    background-color: #692424;
    border-radius: 100px;
    padding: 1.75rem;
    margin: 15px 0;
    height: 1.75rem;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.linkImage:last-child {
    margin-bottom: 0;
    
}

.linkImage>img {
    display: flex;
    height: 125%;
    height: 2.75rem;
    margin: -3%;
}

.linkImage p {
    display: flex;
    font-weight: 600;
    color: #b8b8b8;
    font-size: 18px;
    margin-left: auto;
    margin-top: auto;
    align-items: center;

}
}
/************ Footer Css ************/

  .footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
  }
  